import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import { BlockSerializer } from "../utils/BlockSerializer"

/**
* Single page (/:slug)
*
* This file renders a single page and loads all the content.
*
*/
const Page = ({ data, location }) => {
    const page = data.sanityPage

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="website"
            />
            <Helmet>
                {/*<style type="text/css">{`${page.codeinjection_styles}`}</style>*/}
            </Helmet>
            <Layout>
                <div className="container">
                    <article className="content">
                        <h1 className="content-title">{page.title}</h1>
                        <BlockSerializer>{page._rawBody}</BlockSerializer>
                    </article>
                </div>
            </Layout>
        </>
    )
}

Page.propTypes = {
    data: PropTypes.shape({
        sanityPage: PropTypes.shape({
            //codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            //feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
    query($slug: String!) {
        sanityPage(slugWithRedirectValidation: { slug: {current: {eq: $slug}}}) {
            ...SanityPageFields
        }
    }
`
